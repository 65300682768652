// src/components/Footer.js
import React from 'react';
import { Link, } from 'react-router-dom';
import './Footer.css';  // スタイルシートをインポート

function Footer() {
  return (
    <footer className="footer">
      <Link to="/Inquiry" className='sub'>お問合せ</Link>
    </footer>
  );
}

export default Footer;