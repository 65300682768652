import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useParams } from 'react-router-dom'; // useParamsをインポート
import './MaterialSearch.css';
import axios from 'axios';
import FileList from './FileList';

const MaterialSearch = () => {
  // 状態管理用のフック
  const { searchKeyword } = useParams(); // URLから検索ワードを取得
  const [schoolType, setSchoolType] = useState('');
  const [subject, setSubject] = useState('');
  const [fileType, setFileType] = useState('');
  const [keyword, setKeyword] = useState(''); // フリーワード検索用の状態
  const [files, setFiles] = useState([]); // 全ての教材ファイル
  const [filteredFiles, setFilteredFiles] = useState([]); // フィルタリングされたファイル
  const [currentPageIndex, setCurrentPageIndex] = useState(0); // 現在のページインデックス
  const [error, setError] = useState('');
  const hasScrolled = useRef(false); // スクロール済みかのフラグ

  const filesPerPage = 9; // 1ページあたりの表示ファイル数

  // ドロップダウンの選択肢
  const schoolTypes = ['小学校', '中学校', '高等学校', 'その他校種'];
  const subjects = ['国語', '社会', '地歴公民', '公民', '算数', '数学', '理科', '生活', '音楽', '芸術', '図画工作', '技術家庭', '家庭', '保健体育', '外国語活動', '外国語', '専門科目'];
  const fileTypes = ['PowerPoint', 'PDF', 'Word', 'Excel', '画像形式'];

  // 検索ボタンが押されたときの処理
  const handleSearch = useCallback((keywordOverride, initialFiles = files) => {
    const searchWord = keywordOverride || keyword; // パラメータからのキーワードを優先
    console.log(`Searching for: ${schoolType}, ${subject}, ${fileType}, with keyword: ${searchWord}`);
    let newFilteredFiles = initialFiles;

    // 学校種別、教科、ファイル形式でのフィルタリング
    if (schoolType) {
      newFilteredFiles = newFilteredFiles.filter((file) => file.school_type === schoolType);
    }
    if (subject) {
      newFilteredFiles = newFilteredFiles.filter((file) => file.subject === subject);
    }
    if (fileType) {
      newFilteredFiles = newFilteredFiles.filter((file) => file.file_type === fileType);
    }

    // フリーワード（ファイル名）の条件でフィルタリング（大文字小文字を区別せず部分一致で検索）
    const trimmedKeyword = searchWord.trim();
    if (trimmedKeyword) {
      newFilteredFiles = newFilteredFiles.filter((file) =>
        file.filename && file.filename.toLowerCase().includes(trimmedKeyword.toLowerCase())
      );
    }
  
    console.log('Filtered Files:', newFilteredFiles); // コンソールでフィルタ結果を確認
    setFilteredFiles(newFilteredFiles);
    setCurrentPageIndex(0); // 検索の度に1ページ目に戻す

    // 検索後に一度だけ上から800pxの位置にスクロール
    if (!hasScrolled.current) {
      window.scrollTo({
        top: 800,
        behavior: 'smooth' // スムーズにスクロール
      });
      hasScrolled.current = true; // 一度スクロールしたらフラグを立てる
    }
  }, [files, schoolType, subject, fileType, keyword]); // 依存配列に必要な状態を追加

  // 初回レンダリング時に全ファイルを取得
  useEffect(() => {
    const fetchAllFiles = async () => {
      try {
        const token = localStorage.getItem('token');

        // 全てのファイルを取得するエンドポイント
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/files`, {
          headers: { Authorization: `Bearer ${token}` }
        });

        // ファイルをidの降順で並び替え
        const sortedFiles = response.data.sort((a, b) => b.id - a.id);
        setFiles(sortedFiles);

        // パラメータからのキーワードで検索を自動実行
        if (searchKeyword) {
          setKeyword(searchKeyword);
          handleSearch(searchKeyword, sortedFiles);
        } else {
          setFilteredFiles(sortedFiles); // 初回は全ファイルを表示
        }
      } catch (error) {
        console.error('Error fetching all files:', error);
        setError('ファイルの取得に失敗しました');
      }
    };

    fetchAllFiles();
  }, [searchKeyword, handleSearch]);

  // エンターキーで検索を実行する処理
  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault(); // フォームのリロードを防ぐ
      handleSearch();
    }
  };

  // 現在のページで表示するファイル
  const currentFiles = filteredFiles.slice(currentPageIndex * filesPerPage, (currentPageIndex + 1) * filesPerPage);

  // 総ページ数を計算
  const totalPages = Math.ceil(filteredFiles.length / filesPerPage);

  // 任意のページに移動する処理
  const goToPage = (pageIndex) => {
    setCurrentPageIndex(pageIndex);
    // 2ページ目以降にスクロール
    if (pageIndex !== 0) {
      window.scrollTo({
        top: 100, // 上から100pxの位置にスクロール
      });
    }
  };

  // エラーがある場合の表示
  if (error) {
    return <div>{error}</div>;
  }

  return (
    <div className="search-container">
      <h2>教材検索</h2>
      <form onKeyPress={handleKeyPress}> {/* エンターキーで検索を実行 */}
        <label htmlFor="SchoolType">学校種別</label>
        <select value={schoolType} onChange={e => setSchoolType(e.target.value)} className="select">
          <option value="">----学校種別を選択----</option>
          {schoolTypes.map((type, index) => (
            <option key={index} value={type}>{type}</option>
          ))}
        </select>

        <hr />

        <label htmlFor="Subject">教科</label>
        <select value={subject} onChange={e => setSubject(e.target.value)} className="select">
          <option value="">----教科を選択----</option>
          {subjects.map((subj, index) => (
            <option key={index} value={subj}>{subj}</option>
          ))}
        </select>

        <hr />

        <label htmlFor="FileType">ファイル形式</label>
        <select value={fileType} onChange={e => setFileType(e.target.value)} className="select">
          <option value="">--ファイル形式を選択--</option>
          {fileTypes.map((type, index) => (
            <option key={index} value={type}>{type}</option>
          ))}
        </select>

        <hr />

        <label htmlFor="Keyword">フリーワード</label>
        <input
          type="text"
          id="Keyword"
          value={keyword}
          onChange={e => setKeyword(e.target.value)}
          className="freeword"
          placeholder="ファイル名を入力"
        />
        <div>
          <button type="button" onClick={() => handleSearch()}>教材を検索する</button>
        </div>
      </form>

      <div>
        <div className='search-result'>検索結果</div>
        {/* FileListコンポーネントを利用してファイルリストを表示 */}
        <FileList files={currentFiles} />

        {/* ページングコントロール */}
        <div className="pagination-controls">
          {[...Array(totalPages)].map((_, index) => (
            <span
              key={index}
              onClick={() => goToPage(index)}
              className={`page-number ${index === currentPageIndex ? 'active' : ''}`}
            >
              {index + 1}
            </span>
          ))}
        </div>

        {/* もっとみるの表示 */}
        {(currentPageIndex + 1) * filesPerPage < filteredFiles.length && (
          <p onClick={() => goToPage(currentPageIndex + 1)} className="more">
            もっとみる
          </p>
        )}
      </div>
    </div>
  );
};

export default MaterialSearch;
