import React, { useEffect, useState, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import './UserPage.css';
import UserIconSample from '../assets/iconsample.png';
import axios from 'axios';
import FileList from './FileList';

const UserPage = () => {
  const { username } = useParams();  // URLからユーザーネームを取得
  const [files, setFiles] = useState([]);
  const [userData, setUserData] = useState({
    profileImage: '',
    username: '',
    email: '',
    profileText: ''
  });
  const [error, setError] = useState('');
  const [currentPageIndex, setCurrentPageIndex] = useState(0); // 現在のページインデックス

  const filesPerPage = 9; // 1ページあたりの表示ファイル数

  // ユーザーデータの取得
  const fetchUserData = useCallback(async () => {
    try {
      // ユーザーネームを使ってユーザー情報を取得
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/userbyname/${username}`);
      if (response.data && response.data.user) {
        const user = response.data.user;
        // フィールド名に合わせてuserDataを更新
        setUserData({
          ...user,
          profileImage: user.profile_image, // profile_imageをprofileImageに変換
          profileText: user.profile_text, // profile_textをprofileTextに変換
        });
      } else {
        setError('ユーザーデータが正しく取得できませんでした');
      }
    } catch (error) {
      console.error('Error fetching user data:', error);
      setError('ユーザー情報の取得に失敗しました');
    }
  }, [username]);  // `username`が変わった場合に再生成されるように依存関係を追加

  // ユーザーのファイル一覧を取得
  const fetchFiles = useCallback(async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/userfilesbyname/${username}`);
      // ファイルをidの降順で並び替え
      const sortedFiles = (response.data || []).sort((a, b) => b.id - a.id);
      setFiles(sortedFiles);  // ファイル一覧を設定
    } catch (error) {
      console.error('Error fetching files:', error);
    }
  }, [username]); // `username`が変わった場合に再生成されるように依存関係を追加

  useEffect(() => {
    fetchUserData();
    fetchFiles();
  }, [fetchUserData, fetchFiles]);  // `fetchUserData`と`fetchFiles`の依存関係を指定

  // 任意のページに移動する処理
  const goToPage = (pageIndex) => {
    setCurrentPageIndex(pageIndex);
    // 2ページ目以降にスクロール
    if (pageIndex !== 0) {
      window.scrollTo({
        top: 220, // 上から220pxの位置にスクロール
      });
    }
  };

  // 現在のページで表示するファイル
  const currentFiles = files.slice(currentPageIndex * filesPerPage, (currentPageIndex + 1) * filesPerPage);

  // 総ページ数を計算
  const totalPages = Math.ceil(files.length / filesPerPage);

  // エラーメッセージがある場合の表示
  if (error) {
    return <div>{error}</div>;
  }

  return (
    <div className='userpage'>
      <div className='user-profile'>
        <img 
          src={userData.profileImage || UserIconSample} 
          alt="User Icon" 
          className='user-icon'
        />
        <div className='user-info'>
          <p>ユーザーネーム</p>
          <p>{userData.username}</p>
        </div>
        <div className='profile-sentence'>
          <p>プロフィール</p>
          <p>{userData.profileText || 'プロフィール文が設定されていません。'}</p>
        </div>
      </div>

      <div className="user-material">
        <p>{userData.username}の教材</p>
        {/* FileListコンポーネントを利用してファイルリストを表示 */}
        <FileList files={currentFiles} showDeleteButton={false} />

        {/* ページングコントロール */}
        <div className="pagination-controls">
          {[...Array(totalPages)].map((_, index) => (
            <span
              key={index}
              onClick={() => goToPage(index)}
              className={`page-number ${index === currentPageIndex ? 'active' : ''}`}
            >
              {index + 1}
            </span>
          ))}
        </div>

        {/* もっとみるの表示 */}
        {(currentPageIndex + 1) * filesPerPage < files.length && (
          <p onClick={() => goToPage(currentPageIndex + 1)} className="more">
            もっとみる
          </p>
        )}
      </div>
    </div>
  );
};

export default UserPage;
