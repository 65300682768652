//Login.js

import React, { useContext, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../contexts/AuthContext'; // AuthContextをインポート
import './Login.css';  // スタイルファイルをインポート

function Login() {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const { setIsLoggedIn } = useContext(AuthContext); // ログイン状態を設定する関数
  const navigate = useNavigate(); // useNavigateを使ってnavigateを定義

  const handleLogin = async (e) => {
    e.preventDefault();
  
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/login`, {
        username,
        password
      });
  
      if (response.data.token) {
        localStorage.setItem('token', response.data.token);  // トークンを保存
        alert('ログイン成功！');
        setIsLoggedIn(true); // ログイン状態を更新
        navigate('/mypage'); // アップロード成功時にマイページに遷移
      }
    } catch (error) {
      alert('ログインに失敗しました');
    }
  };

  return (
    <div className="login-container">
      <h2>ログイン</h2>
      <form onSubmit={handleLogin}>
        <div className='type'>
          <label htmlFor="username">ユーザー名：</label>
          <div className='box'>
            <input 
              type="text" 
              id="username" 
              name="username" 
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              placeholder="ユーザー名" 
              required 
            />
          </div>
        </div>
        <div className='type'>
          <label htmlFor="password">パスワード：</label>
          <div className='box'>
            <input 
              type="password" 
              id="password" 
              name="password" 
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              placeholder="パスワード" 
              required 
            />
          </div>
        </div>
        <button type="submit">ログインする</button>
      </form>
    </div>
  );
}

export default Login;
