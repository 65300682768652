import React, { useEffect, useState } from 'react';
import './MaterialDetail.css';
import { useParams, Link } from 'react-router-dom';
import axios from 'axios';
import { Viewer, Worker } from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css';

const pdfWorkerVersion = '3.11.174'; // PDF.jsのバージョン

const MaterialDetail = () => {
  const { id } = useParams(); // URLの:idパラメータを取得
  const [file, setFile] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false); // モーダル表示用のステート

  // モーダルの表示・非表示をトグルする関数
  const toggleModal = () => setIsModalOpen(!isModalOpen);

  useEffect(() => {
    const fetchFile = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/files/${id}`);
        setFile(response.data);
      } catch (error) {
        console.error('Error fetching file:', error);
      }
    };

    fetchFile();
  }, [id]);

  // ファイルプレビューのレンダリング
  const renderFilePreview = (file) => {
    // 直接 `file.path` を使用して `fileUrl` を生成
    const fileUrl = file?.path || '';

    const previewStyle = {
      height: '500px',   // プレビューの高さ
      cursor: 'pointer', // クリック可能なカーソルに変更
      objectFit: 'cover',
    };

    // ファイル形式に応じたプレビューを表示
    if (file.file_type?.toLowerCase() === 'pdf') {
      return (
        <div style={previewStyle} onClick={toggleModal}>
          <Worker workerUrl={`https://unpkg.com/pdfjs-dist@${pdfWorkerVersion}/build/pdf.worker.min.js`}>
            <Viewer fileUrl={fileUrl} />
          </Worker>
        </div>
      );
    } 
    // 画像形式として`.ico`ファイルも認識するように変更
    else if (file.file_type?.toLowerCase() === '画像形式' || file.filename.toLowerCase().endsWith('.ico')) {
      return <img src={fileUrl} alt={file.filename} style={previewStyle} onClick={toggleModal} />;
    } 
    else if (['word', 'excel', 'powerpoint'].includes(file.file_type?.toLowerCase())) {
      return (
        <div style={previewStyle} onClick={toggleModal}>
          <iframe
            src={`https://view.officeapps.live.com/op/embed.aspx?src=${encodeURIComponent(fileUrl)}`}
            style={{ width: '100%', height: '100%', pointerEvents: 'none' }}
            frameBorder="0"
            title="Office Preview"
          ></iframe>
        </div>
      );
    } else {
      return <div>プレビューはこのファイル形式に対応していません</div>;
    }
  };

  // モーダルの内容をレンダリング
  const renderModalContent = () => {
    const fileUrl = file?.path || '';

    return (
      <div className="modal-content">
        {file.file_type?.toLowerCase() === 'pdf' ? (
          <Worker workerUrl={`https://unpkg.com/pdfjs-dist@${pdfWorkerVersion}/build/pdf.worker.min.js`}>
            <Viewer fileUrl={fileUrl} />
          </Worker>
        ) : file.file_type?.toLowerCase() === '画像形式' || file.filename.toLowerCase().endsWith('.ico') ? (
          <img src={fileUrl} alt={file.filename} style={{ width: '100%', maxHeight: '80vh' }} />
        ) : (
          <iframe
            src={`https://view.officeapps.live.com/op/embed.aspx?src=${encodeURIComponent(fileUrl)}`}
            style={{ width: '100%', height: '80vh' }}
            frameBorder="0"
            title="Office Preview"
          ></iframe>
        )}
      </div>
    );
  };

  // fileが存在しない場合はローディングやエラーメッセージを表示
  if (!file) return <div>Loading...</div>;

  return (
    <div className="material-detail">
      {/* プレビュー画面 */}
      <div className="file-item">
        <div className="file-info">
          <div className="file-preview">{renderFilePreview(file)}</div>
          <div className="class_a">
            {/* その他の情報 */}
            <div className="item_c">
              <p className="item_filename">{file.filename}</p>
            </div>
            <div className="class_b">
              <p className="item_a">学校種別</p>
              <p className="item_b">{file.school_type}</p>
            </div>
            <div className="class_b">
              <p className="item_a">教科</p>
              <p className="item_b">{file.subject}</p>
            </div>
            <div className="class_b">
              <p className="item_a">投稿者</p>
              <p className="item_b"><Link to={`/userpage/${file.user_name}`}>{file.user_name}</Link></p>
            </div>
            <div className="class_b">
              <p className="item_a">投稿日</p>
              <p className="item_b">{new Date(file.upload_date).toLocaleDateString()}</p>
            </div>
            <div className="class_b">
              <p className="item_a">評価</p>
              <p className="star">{'★'.repeat(file.rating)}</p>
            </div>
            <div className="download_btn">
              <a href={file.path} className="download" download>資料をダウンロード</a>
            </div>
          </div>
        </div>
      </div>

      {/* モーダルの表示 */}
      {isModalOpen && (
        <div className="modal-overlay" onClick={toggleModal}>
          <div className="modal-container" onClick={(e) => e.stopPropagation()}>
            {renderModalContent()}
            <button className="close-modal" onClick={toggleModal}>
              閉じる
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default MaterialDetail;
