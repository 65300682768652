//app.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'; // ページ遷移用のRouter
import { AuthProvider } from './contexts/AuthContext'; // AuthProviderをインポート
import './App.css';  // グローバルCSSのインポート
import Header from './components/Header';  // Header コンポーネントをインポート
import Footer from './components/Footer';  // Footer コンポーネントをインポート
import MainContent from './components/MainContent';  // MainContent コンポーネントをインポーネット
import Register from './components/Register';  // Register コンポーネントをインポート
import Subject from './components/Subject';  // 教科ごとのページ表示用コンポーネント
import MaterialSearch from './components/MaterialSearch';  // MaterialSearch コンポーネントをインポート
import Login from './components/Login';  // Login コンポーネントをインポート
import UserPage from './components/UserPage';
import MyPage from './components/MyPage';  // MyPage コンポーネントをインポート
import Upload from './components/Upload';  // Upload コンポーネントをインポート
import MaterialDetail from './components/MaterialDetail';  // MaterialDetail コンポーネントをインポート
import Inquiry from './components/Inquiry';  // Inquiry コンポーネントをインポート
import FileList from './components/FileList'; // 新しく作成したコンポーネントをインポート


function App() {
  return (
    <AuthProvider> {/* AuthProvider でアプリ全体をラップ */}
      <Router>
        <div className="App">

          {/* Header コンポーネントを使用 */}
          <Header />  

          <Routes>
            {/* トップページ */}
            <Route path="/" element={<MainContent />} />

            {/* 会員登録ページ */}
            <Route path="/register" element={<Register />} />

            {/* 教材探索ページ */}
            <Route path="/MaterialSearch" element={<MaterialSearch />} />

            {/* ログインページ */}
            <Route path="/login" element={<Login />} />

            {/* ユーザーページ */}
            <Route path="/userpage/:username" element={<UserPage />} /> {/* ここでUserPageを指定 */}

            {/* マイページ */}
            <Route path="/MyPage" element={<MyPage />} />

            {/* アップロードページ */}
            <Route path="/Upload" element={<Upload />} />

            {/* 教材詳細ページ */}
            <Route path="/MaterialDetail/:id" element={<MaterialDetail />} />

            {/* お問合せフォームページ */}
            <Route path="/Inquiry" element={<Inquiry />} />

            {/* ファイルの一覧 */}
            <Route path="/FileList" element={<FileList />} />

            {/* MaterialSearchにパスパラメータを渡す */}
            <Route path="/MaterialSearch/:searchKeyword" element={<MaterialSearch />} />
            
            {/* 教科ごとのページ */}
            <Route path="/elem" element={<Subject schoolType="小学校"/>} />
            <Route path="/elem-ja" element={<Subject schoolType="小学校" subject="国語" />} />
            <Route path="/elem-so" element={<Subject schoolType="小学校" subject="社会" />} />
            <Route path="/elem-ma" element={<Subject schoolType="小学校" subject="算数" />} />
            <Route path="/elem-sc" element={<Subject schoolType="小学校" subject="理科" />} />
            <Route path="/elem-li" element={<Subject schoolType="小学校" subject="生活" />} />
            <Route path="/elem-mu" element={<Subject schoolType="小学校" subject="音楽" />} />
            <Route path="/elem-ar" element={<Subject schoolType="小学校" subject="図画工作" />} />
            <Route path="/elem-ho" element={<Subject schoolType="小学校" subject="家庭" />} />
            <Route path="/elem-ph" element={<Subject schoolType="小学校" subject="保健体育" />} />
            <Route path="/elem-fo" element={<Subject schoolType="小学校" subject="外国語活動" />} />
            <Route path="/juni" element={<Subject schoolType="中学校"/>} />
            <Route path="/juni-ja" element={<Subject schoolType="中学校" subject="国語" />} />
            <Route path="/juni-so" element={<Subject schoolType="中学校" subject="社会" />} />
            <Route path="/juni-ma" element={<Subject schoolType="中学校" subject="数学" />} />
            <Route path="/juni-sc" element={<Subject schoolType="中学校" subject="理科" />} />
            <Route path="/juni-fo" element={<Subject schoolType="中学校" subject="外国語" />} />
            <Route path="/juni-mu" element={<Subject schoolType="中学校" subject="音楽" />} />
            <Route path="/juni-ar" element={<Subject schoolType="中学校" subject="美術" />} />
            <Route path="/juni-ph" element={<Subject schoolType="中学校" subject="保健体育" />} />
            <Route path="/juni-th" element={<Subject schoolType="中学校" subject="技術家庭" />} />
            <Route path="/high" element={<Subject schoolType="高等学校"/>} />
            <Route path="/high-ja" element={<Subject schoolType="高等学校" subject="国語" />} />
            <Route path="/high-gh" element={<Subject schoolType="高等学校" subject="地理歴史" />} />
            <Route path="/high-pu" element={<Subject schoolType="高等学校" subject="公民" />} />
            <Route path="/high-ma" element={<Subject schoolType="高等学校" subject="数学" />} />
            <Route path="/high-sc" element={<Subject schoolType="高等学校" subject="理科" />} />
            <Route path="/high-fo" element={<Subject schoolType="高等学校" subject="外国語" />} />
            <Route path="/high-ph" element={<Subject schoolType="高等学校" subject="保健体育" />} />
            <Route path="/high-ar" element={<Subject schoolType="高等学校" subject="芸術" />} />
            <Route path="/high-ho" element={<Subject schoolType="高等学校" subject="家庭" />} />
            <Route path="/high-sp" element={<Subject schoolType="高等学校" subject="専門科目" />} />
            <Route path="/other" element={<Subject schoolType="その他校種"/>} />
            <Route path="/kinder-garden" element={<Subject schoolType="幼稚園" subject="幼稚園" />} />
            <Route path="/support-sc" element={<Subject schoolType="特別支援学校" subject="特別支援学校" />} />
          </Routes>

          {/* Footer コンポーネントを使用 */}
          <Footer />
          
        </div>
      </Router>
    </AuthProvider>
  );
}

export default App;
