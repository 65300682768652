import React, { useEffect, useState, useCallback } from 'react';
import './Subject.css';
import axios from 'axios';
import FileList from './FileList';

const Subject = ({ schoolType, subject }) => {
  const [files, setFiles] = useState([]);
  const [error, setError] = useState('');

  // サーバーからファイル一覧を取得
  const fetchFiles = useCallback(async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/files`);

      // フィルタリングの条件分岐
      const filteredFiles = response.data.filter((file) => {
        // 教科が選択されている場合、学校種別と教科でフィルタリング
        if (subject) {
          return file.school_type === schoolType && file.subject === subject;
        }
        // 教科が選択されていない場合、学校種別だけでフィルタリング
        return file.school_type === schoolType;
      });

      setFiles(filteredFiles);
    } catch (error) {
      console.error('Error fetching files:', error);
      setError('ファイルの取得に失敗しました');
    }
  }, [schoolType, subject]); // `schoolType`と`subject`を依存関係に追加

  useEffect(() => {
    fetchFiles();
  }, [fetchFiles]);

  return (
    <div className="subject-page">
      <h1>{schoolType}{subject ? ` - ${subject}` : ''}の教材一覧</h1>
      {error && <p>{error}</p>}
      <FileList files={files} showDeleteButton={false} /> {/* 削除ボタンは表示しない */}
    </div>
  );
};

export default Subject;
