import React, { useEffect, useState, useRef, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';  
import './MyPage.css';  
import UserIconSample from '../assets/iconsample.png';  
import axios from 'axios';
import FileList from './FileList'; 

const MyPage = () => {
  const [files, setFiles] = useState([]);  
  const [userData, setUserData] = useState({
    profileImage: '',
    username: '',
    email: '',
    profileText: '',
    userId: null,
  }); 
  const [isEditing, setIsEditing] = useState(false);  
  const [newProfileImage, setNewProfileImage] = useState(null);  
  const [newProfileImagePreview, setNewProfileImagePreview] = useState(null);  
  const [error, setError] = useState('');  
  const [currentPageIndex, setCurrentPageIndex] = useState(0); // 現在のページインデックス
  const navigate = useNavigate();  

  const filesPerPage = 9; // 1ページあたりの表示ファイル数
  const fileInputRef = useRef(null); // ファイル選択用のinputのref

  // APIリクエストでファイル一覧を取得する関数
  const fetchFiles = useCallback(async (token, username) => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/myfiles`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      // 現在ログインしているユーザーの投稿のみフィルタリング
      const userFiles = response.data.filter(file => file.user_name === username);
      
      // ファイルをidの降順で並び替え
      const sortedFiles = userFiles.sort((a, b) => b.id - a.id);
      
      setFiles(sortedFiles);  
    } catch (error) {
      console.error('Error fetching files:', error);
      setError('ファイルの取得に失敗しました');
    }
  }, []);

  // 認証されたユーザー情報を取得
  const fetchUserData = useCallback(async (token) => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/profile`, {
        headers: { Authorization: `Bearer ${token}` }
      });
      if (response.data && response.data.user) {
        setUserData({
          ...response.data.user,
          userId: response.data.user.userId,
        });

        // ユーザーデータ取得後にファイル取得を呼び出す
        fetchFiles(token, response.data.user.username);
      } else {
        setError('ユーザーデータが正しく取得できませんでした');
      }
    } catch (error) {
      console.error('Error fetching user data:', error);
      setError('認証エラーが発生しました');
    }
  }, [fetchFiles]);

  // 初期データ取得
  useEffect(() => {
    const token = localStorage.getItem('token');
    if (!token) {
      setError('ログインが必要です');
      navigate('/login');
      return;
    }
  
    // トークンがある場合はユーザー情報とファイル情報を取得
    fetchUserData(token);
  }, [navigate, fetchUserData]);

  // 任意のページに移動する処理
  const goToPage = (pageIndex) => {
    setCurrentPageIndex(pageIndex);
    // 2ページ目以降にスクロール
    if (pageIndex !== 0) {
      window.scrollTo({
        top: 220, // 上から100pxの位置にスクロール
      });
    }
  };

  // 現在のページで表示するファイル
  const currentFiles = files.slice(currentPageIndex * filesPerPage, (currentPageIndex + 1) * filesPerPage);

  // 総ページ数を計算
  const totalPages = Math.ceil(files.length / filesPerPage);

  // プロフィール編集モードの切り替え
  const handleEditClick = () => {
    setIsEditing(true);
  };

  // プロフィール画像の変更処理（プレビュー用）
  const handleProfileImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setNewProfileImage(file);
      const reader = new FileReader();
      reader.onloadend = () => {
        setNewProfileImagePreview(reader.result);  
      };
      reader.readAsDataURL(file);
    }
  };

  // 画像クリックでファイル選択をトリガー
  const handleImageClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click(); 
    }
  };

  // 編集内容を保存
  const handleSave = async () => {
    try {
      const token = localStorage.getItem('token');
      const formData = new FormData();

      formData.append('username', userData.username);
      formData.append('email', userData.email);
      formData.append('profileText', userData.profileText);  

      if (newProfileImage) {
        formData.append('profileImage', newProfileImage);
      }

      await axios.put(`${process.env.REACT_APP_API_URL}/profile`, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'multipart/form-data'
        }
      });

      setIsEditing(false);
      setNewProfileImage(null);
      setNewProfileImagePreview(null);
      alert('プロフィールが更新されました。プロフィール画像の更新は、少し時間がかかります。');
      navigate('/mypage');  
    } catch (error) {
      console.error('Error saving profile data:', error);
      setError('プロフィールの保存に失敗しました。');
    }
  };

  // マイページに戻る（プレビューをクリア）
  const handleCancel = () => {
    setIsEditing(false);
    setNewProfileImage(null);  
    setNewProfileImagePreview(null);  
    navigate('/mypage');
  };

  // ファイル削除の関数
  const handleDeleteFile = async (fileId, fileKey) => {
    const confirmDelete = window.confirm('本当に削除しますか？');
    console.log('Deleting file with key:', fileKey);
    
    if (confirmDelete) {
      try {
        const token = localStorage.getItem('token');
        await axios.delete(`${process.env.REACT_APP_API_URL}/delete-file`, {
          headers: { Authorization: `Bearer ${token}` },
          data: {
            fileKey: fileKey,
            fileId: fileId,  
          }
        });
  
        setFiles(files.filter(file => file.id !== fileId));
        alert('ファイルが削除されました');
      } catch (error) {
        console.error('Error deleting file:', error);
        setError('ファイルの削除に失敗しました');
      }
    }
  };

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <div className='my-page'>
      <div className='my-profile'>
        <img 
          src={newProfileImagePreview || (userData && userData.profileImage ? userData.profileImage : UserIconSample)} 
          alt="User Icon" 
          className='my-icon'
          onClick={isEditing ? handleImageClick : null}  
          style={{ cursor: isEditing ? 'pointer' : 'default' }}  
        />
        {isEditing && (
          <input
            type="file"
            ref={fileInputRef}
            onChange={handleProfileImageChange}
            style={{ display: 'none' }}  
            accept="image/*"
          />
        )}
        <div className='user-info'>
          <p>ユーザーネーム</p>
          <p>
          {isEditing ? (
            <input 
              className="myname-input"
              value={userData.username} 
              onChange={(e) => setUserData({...userData, username: e.target.value})}
            />
          ) : (
            userData.username
          )}
          </p>
        </div>
        <div className='profile-sentence'>
          <p>プロフィール</p>
          <p>
            {isEditing ? (
              <textarea 
                className="profile-textarea"
                value={userData.profileText} 
                onChange={(e) => setUserData({...userData, profileText: e.target.value})}
              />
            ) : (
              userData.profileText || 'プロフィール文が設定されていません。'
            )}
          </p>
        </div>
      </div>
      {isEditing && (
          <div className="mypage-btns">
            <button onClick={handleSave} className="mypage-btn">保存</button>
            <button onClick={handleCancel} className="mypage-btn">マイページに戻る</button>
          </div>
        )}

      {!isEditing && (
        <>
          <div className="mypage-btns">
            <button onClick={() => navigate('/Upload')} className="mypage-btn">資料をアップロード</button>
            <button onClick={handleEditClick} className="mypage-btn">プロフィールを編集</button>
          </div>

          <div className="my-material">
            <p>{userData.username}の教材</p>
            {/* FileListコンポーネントを利用してファイルリストを表示 */}
            <FileList files={currentFiles} showDeleteButton={true} onDelete={handleDeleteFile} />

            {/* ページングコントロール */}
            <div className="pagination-controls">
              {[...Array(totalPages)].map((_, index) => (
                <span
                  key={index}
                  onClick={() => goToPage(index)}
                  className={`page-number ${index === currentPageIndex ? 'active' : ''}`}
                >
                  {index + 1}
                </span>
              ))}
            </div>

            {/* もっとみるの表示 */}
            {(currentPageIndex + 1) * filesPerPage < files.length && (
              <p onClick={() => goToPage(currentPageIndex + 1)} className="more">
                もっとみる
              </p>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default MyPage;
