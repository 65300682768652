// register.js

import React, { useState } from 'react';
import axios from 'axios';
import './Register.css'; // スタイルファイルをインポート
import { useNavigate } from 'react-router-dom';

function Register() {
  const [username, setUsername] = useState('');  // ユーザー名の状態を管理
  const [email, setEmail] = useState('');
  const [emailConfirm, setEmailConfirm] = useState('');
  const [password, setPassword] = useState('');
  const [passwordConfirm, setPasswordConfirm] = useState('');
  const [year, setYear] = useState('');   // 年
  const [month, setMonth] = useState(''); // 月
  const [day, setDay] = useState('');     // 日
  const [errors, setErrors] = useState({});
  const [passwordStrength, setPasswordStrength] = useState('');
  const navigate = useNavigate(); // useNavigateを使ってnavigateを定義

  const validatePasswordStrength = (password) => {
    let strength = '';
    const lengthCheck = password.length >= 8;
    const lowerCaseCheck = /[a-z]/.test(password);
    const upperCaseCheck = /[A-Z]/.test(password);
    const numberCheck = /[0-9]/.test(password);
    const specialCharCheck = /[!@#$%^&*]/.test(password);

    if (lengthCheck && lowerCaseCheck && upperCaseCheck && numberCheck && specialCharCheck) {
      strength = '強い';
    } else if (lengthCheck && (lowerCaseCheck || upperCaseCheck) && (numberCheck || specialCharCheck)) {
      strength = '普通';
    } else {
      strength = '弱い';
    }

    setPasswordStrength(strength);
  };

  const validateForm = () => {
    const newErrors = {};
    if (email !== emailConfirm) {
      newErrors.emailConfirm = 'メールアドレスが一致しません';
    }
    if (password !== passwordConfirm) {
      newErrors.passwordConfirm = 'パスワードが一致しません';
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handlePasswordChange = (e) => {
    const newPassword = e.target.value;
    setPassword(newPassword);
    validatePasswordStrength(newPassword);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      try {
        // バックエンドにデータを送信
        await axios.post(`${process.env.REACT_APP_API_URL}/register`, {
          username,  // ユーザー名を追加して送信
          email,
          password,
          birthdate: `${year}-${month}-${day}`,  // 生年月日をまとめて送信
        });

        // ポップアップでメッセージを表示
        alert('登録が完了しました！');
        navigate('/mypage'); // 会員登録成功時にマイページに遷移
      } catch (error) {
        // エラー時の処理
        alert('登録ができませんでした。');
      }
    }
  };

  const generateYearOptions = () => {
    const years = [];
    for (let i = 2023; i >= 1900; i--) {
      years.push(<option key={i} value={i}>{i}</option>);
    }
    return years;
  };

  const generateMonthOptions = () => {
    const months = [];
    for (let i = 1; i <= 12; i++) {
      months.push(<option key={i} value={i}>{i}</option>);
    }
    return months;
  };

  const generateDayOptions = () => {
    const days = [];
    for (let i = 1; i <= 31; i++) {
      days.push(<option key={i} value={i}>{i}</option>);
    }
    return days;
  };

  return (
    <div className="register-container">
      <h2>会員登録</h2>
      <form onSubmit={handleSubmit}>
        <div className='type'>
          <label htmlFor="username" className='item'>ユーザー名<span className="required">（必須）</span></label>
          <div>
            <input
              type="text"
              id="username"
              name="username"
              placeholder="ユーザー名"
              value={username}
              onChange={(e) => setUsername(e.target.value)}  // ユーザー名の状態を更新
              autoComplete="username"
              required
            />
          </div>
        </div>

        <hr />

        <div className='type'>
          <label htmlFor="email" className='item'>メールアドレス<span className="required">（必須）</span></label>
          <div>
            <input
              type="email"
              id="email"
              name="email"
              placeholder="メールアドレス"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              autoComplete="email"
              required
            />
            <input
              type="email"
              id="email-confirm"
              name="email-confirm"
              placeholder="確認のためもう一度入力"
              value={emailConfirm}
              onChange={(e) => setEmailConfirm(e.target.value)}
              autoComplete="email"
              required
            />
            {errors.emailConfirm && <span className="error">{errors.emailConfirm}</span>}
          </div>
        </div>

        <hr />

        <div className='type'>
          <label htmlFor="password" className='item'>パスワード<span className="required">（必須）</span></label> 
          <div>
            <input
              type="password"
              id="password"
              name="password"
              placeholder="パスワード"
              value={password}
              onChange={handlePasswordChange}
              autoComplete="new-password"
              required
            />
            <span className={`password-strength ${passwordStrength.toLowerCase()}`}>
              パスワードの強度: {passwordStrength}
            </span>
            <input
              type="password"
              id="password-confirm"
              name="password-confirm"
              placeholder="確認のためもう一度入力"
              value={passwordConfirm}
              onChange={(e) => setPasswordConfirm(e.target.value)}
              autoComplete="new-password"
              required
            />
            {errors.passwordConfirm && <span className="error">{errors.passwordConfirm}</span>}
            {errors.password && <span className="error">{errors.password}</span>}
          </div>
        </div>

        <hr />

        <div className='type'>
          <label htmlFor="year" className='item'>生年月日<span className="required">（必須）</span></label>
          <div className="birthdate-container">
            <select name="year" id="year" value={year} onChange={(e) => setYear(e.target.value)} required>
              <option value="">西暦</option>
              {generateYearOptions()}
            </select>
            <select name="month" id="month" value={month} onChange={(e) => setMonth(e.target.value)} required>
              <option value="">月</option>
              {generateMonthOptions()}
            </select>
            <select name="day" id="day" value={day} onChange={(e) => setDay(e.target.value)} required>
              <option value="">日</option>
              {generateDayOptions()}
            </select>
          </div>
        </div>

        <button type="submit">登録</button>
      </form>
    </div>
  );
}

export default Register;
