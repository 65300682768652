import React, { useEffect, useState } from 'react';
import './MainContent.css';
import { Link } from 'react-router-dom';
import libraryImage from '../assets/library.jpg';
import axios from 'axios';
import FileList from './FileList';
import { Timeline } from 'react-twitter-widgets'; // Twitterのウィジェットコンポーネントをインポート

const MainContent = () => {
  const [files, setFiles] = useState([]);
  const [currentPageIndex, setCurrentPageIndex] = useState(0); // 現在のページインデックス
  const filesPerPage = 9; // 1ページあたりの表示ファイル数

  console.log('API URL:', process.env.REACT_APP_API_URL);

  // サーバーからファイル一覧を取得
  const fetchFiles = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/files`);
      // ファイルを新しい順に並び替え（IDの降順でソート）
      const sortedFiles = response.data.sort((a, b) => b.id - a.id);
      setFiles(sortedFiles);
    } catch (error) {
      console.error('Error fetching files:', error);
    }
  };

  useEffect(() => {
    fetchFiles();
  }, []);

  // 現在のページで表示するファイル
  const currentFiles = files.slice(currentPageIndex * filesPerPage, (currentPageIndex + 1) * filesPerPage);

  // 総ページ数を計算
  const totalPages = Math.ceil(files.length / filesPerPage);

  // 任意のページに移動する処理
  const goToPage = (pageIndex) => {
    setCurrentPageIndex(pageIndex);
    // 2ページ目以降にスクロール
    if (pageIndex !== 0) {
      window.scrollTo({
        top: 220, // 上から220pxの位置にスクロール
      });
    }
  };

  return (
    <main className="main-content">
      {/* currentPageIndexが0のときのみ表示 */}
      {currentPageIndex === 0 && (
        <div>
          <div className="image-container">
            <img src={libraryImage} alt="Library" className="library-image" />
            <div className="overlay"></div>
            <div className="welcome-text">ようこそedushareへ</div>
            <div className="description-text">
              授業者たちが、クリエイティブな<br />アイディアを共有し合う場所
            </div>
          </div>
          <div className="main-btn">
            <Link to="./MaterialSearch" className="MaterialSearch">アイディアを探索する</Link>
          </div>
        </div>
      )}
      
      {/* main-materialsにcurrentPageIndexが0かどうかでクラスを付与 */}
      <div className={`main-materials ${currentPageIndex !== 0 ? 'page-offset' : ''}`}>
        <p className="latest">最新の資料</p>
        
        {/* 現在のページで表示するファイルをFileListに渡す */}
        <FileList files={currentFiles} />

        {/* もっとみるの文字表示（右詰め） */}
        {(currentPageIndex + 1) * filesPerPage < files.length && (
          <p onClick={() => goToPage(currentPageIndex + 1)} className="more">
            もっとみる
          </p>
        )}

        {/* ページングコントロール */}
        <div className="pagination-controls">
          {/* ページ番号を表示 */}
          {[...Array(totalPages)].map((_, index) => (
            <span
              key={index}
              onClick={() => goToPage(index)}
              className={`page-number ${index === currentPageIndex ? 'active' : ''}`}
            >
              {index + 1}
            </span>
          ))}
        </div>

        {/* Twitterタイムライン */}
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', margin: '40px 0' }}>
          {/* Twitterウィジェットのコンテナ */}
          <div style={{ transform: 'scale(0.8)', transformOrigin: 'center', width: '100%', maxWidth: ' 1000px' }}>
            <Timeline
              dataSource={{
                sourceType: 'profile',
                screenName: 'mathroom314',
              }}
              options={{ height: 600, width: 1000 }}
              theme="light"
            />
          </div>
        </div>

        <p className="recommend">オススメの資料</p>
        <p className="more">もっとみる</p>
      </div>
    </main>
  );
};

export default MainContent;
